import React from 'react'

import DefaultSEO from '@/components/SEO/DefaultSeo'

import Footer from '@/components/Footer'
import Navbar from '@/components/Navbar'

const TemplateWrapper = ({ children, hideNav }) => {
  return (
    <div>
      <DefaultSEO />
      <div className='h-screen'>
        <Navbar />
        <main className='wrapper'>{children}</main>
        <Footer />
      </div>
    </div>
  )
}

export default TemplateWrapper
