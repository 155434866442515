import React from 'react'
import { Link } from 'gatsby'
import LogoHorizontal from '@/components/Icons/LogoHorizontal'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className='footer'>
        <div className='container-lg'>
          <div className='text-center'>
            <div className='footer__logo'>
              <LogoHorizontal />
            </div>
            <p className='text-center text-grey-dark text-sm'>
              Capital Food Market is an Elanor Investors project
            </p>
          </div>
          <hr className='seperator' />
          <div className='footer__bottom'>
            <div className='footer__left'></div>
            <a
              href='https://cleancommit.io'
              rel='noopener'
              className='footer__created'>
              Created by cleancommit.io
            </a>
            <div className='footer__right'>
              <Link to='/privacy-policy/'>Privacy Policy</Link>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
